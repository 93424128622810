import { template } from "@ember/template-compiler";
import Component from "@glimmer/component";
import { on } from "@ember/modifier";
import { action } from "@ember/object";
import { service } from "@ember/service";
import { or } from "truth-helpers";
import concatClass from "discourse/helpers/concat-class";
import { wantsNewWindow } from "discourse/lib/intercept-click";
import i18n from "discourse-common/helpers/i18n";
import Notifications from "./user-dropdown/notifications";
export default class UserDropdown extends Component {
    @service
    currentUser;
    @action
    click(e1) {
        if (wantsNewWindow(e1)) {
            return;
        }
        e1.preventDefault();
        this.args.toggleUserMenu();
        // remove the focus of the header dropdown button after clicking
        e1.target.tagName.toLowerCase() === "button" ? e1.target.blur() : e1.target.closest("button").blur();
    }
    static{
        template(`
    <li
      id="current-user"
      class={{concatClass
        (if @active "active")
        "header-dropdown-toggle current-user user-menu-panel"
      }}
    >
      <button
        class="icon btn-flat"
        aria-haspopup="true"
        aria-expanded={{@active}}
        aria-label={{i18n
          "user.account_possessive"
          name=(or this.currentUser.name this.currentUser.username)
        }}
        {{on "click" this.click}}
      >
        <Notifications @active={{@active}} />
      </button>
    </li>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
